<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <div class="serarch">
        <div class="searchleft">
          <el-form :inline="true" :model="searchform" class="demo-form-inline">
            <el-form-item class="input-item">
              <el-input
                placeholder="请输入用户名/手机号/身份证号"
                v-model.trim="inputSeache"
                @keyup.enter.native="pageNum=1;getAdminUser()"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search lh"
                  @click="pageNum=1;getAdminUser()"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="userStatus"
                @change="pageNum=1;getAdminUser()"
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未冻结" value="0"></el-option>
                <el-option label="已冻结" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型">
              <el-select
                v-model="userType"
                @change="pageNum=1;getAdminUser()"
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="普通人才" value="1"></el-option>
                <el-option label="高级人才" value="2"></el-option>
                <el-option label="零工" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="性别">
              <el-select
                v-model="userGender"
                @change="pageNum=1;getAdminUser()"
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="searchright">
          <!-- <el-button type="primary" style="margin:10px 40px" size="small" @click="addUserBtn"  class="mybotton">添加用户</el-button> -->
        </div>
      </div>
      <div class="list" v-loading="loading">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'font-weight': 'bold',
            color: '#555555',
            padding: '6px 0',
          }"
          style="width: 100%"
          v-loading="loading"
          border
          stripe
          row-key="id"
        >
          <el-table-column prop="id" align="center" label="ID" width="100">
          </el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            label="用户名"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="heardImg"
            align="center"
            label="头像"
            width="100"
          >
            <template slot-scope="scope">
              <show-img
                v-if="scope.row.heardImg"
                :url="scope.row.heardImg"
                width="300"
                wid="40px"
              ></show-img>
              <show-img
                v-else
                :url="LogoUrl"
                width="300"
                wid="40px"
              ></show-img>
            </template>
          </el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            label="手机号"
          >
          </el-table-column>
          <el-table-column
            prop="gender"
            align="center"
            label="性别"
          >
            <template slot-scope="scope">
              {{ scope.row.gender == 1 ? "男" :scope.row.gender==2? "女":""}}
              <i
                v-if="scope.row.gender == 1"
                class="el-icon-male"
                style="color: rgb(30 144 255)"
              ></i>
              <i
                v-else-if="scope.row.gender == 2"
                class="el-icon-female"
                style="color: rgb(255 106 106)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column prop="age" align="center" label="年龄" >
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="typeName"
            align="center"
            label="求职者类型"
          >
            <template slot-scope="scope">
              <el-select
                style="width: 120px"
                v-model="scope.row.typeName"
                @change="changeUserType($event, scope.row.id)"
                size="small"
                placeholder="请选择"
              >
                <el-option label="普通人才" :value="1"></el-option>
                <el-option label="高级人才" :value="2"></el-option>
                <el-option label="零工" :value="3"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" label="是否冻结">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="true"
                :inactive-value="false"
                active-color="#404040"
                inactive-color="#b8b8b8"
                active-text=""
                inactive-text=""
                @change="changestatu($event, scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="" width="180" align="center" label="操作">
            <template slot-scope="scope">
              <!-- <el-button type="danger" size="mini" class="mybotton3" @click="handleCreate(scope.row.id)">编辑</el-button> -->
              <el-button
                type="primary"
                @click="searchUser(scope.row.id)"
                >查看</el-button
              >
              <el-button
                type="danger"
                @click="deleteUser(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <app-page
        :total="total"
        :pageSize="pageSize"
        :current="pageNum"
        @handleCurrentChange="currentchange"
        @handleSizeChange="handchange"
      ></app-page>
    </div>
    <!-- 添加 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formAddAdmin"
        :rules="rules"
        ref="formAddAdmin"
      >
        <el-form-item label="头像">
          <div class="block">
            <upload-img
              :modeldata="formAddAdmin"
              @changeurl="getimgurl"
              style="margin-top: 15px"
            ></upload-img>
          </div>
        </el-form-item>
        <el-form-item label="用户名" prop="account">
          <el-input v-model="formAddAdmin.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="formAddAdmin.password"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="userName">
          <el-input v-model="formAddAdmin.userName"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="newUserRoleId">
          <el-select
            v-model="formAddAdmin.newUserRoleId"
            size="small"
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="addUser('formAddAdmin')"
            :loading="btnLoad"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formAddAdmin1"
        :rules="rules"
        ref="formAddAdmin1"
      >
        <el-form-item label="用户名">
          <el-tag type="info">{{ formAddAdmin1.account }}</el-tag>
        </el-form-item>
        <el-form-item label="头像" prop="">
          <div class="block">
            <upload-img
              :modeldata="formAddAdmin1"
              @changeurl="getimgurl"
              style="margin-top: 15px"
            ></upload-img>
          </div>
        </el-form-item>
        <el-form-item label="昵称" prop="userName">
          <el-input v-model="formAddAdmin1.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="newPassword">
          <div v-if="isShowPwd">
            <el-input
              v-model="formAddAdmin1.newPassword"
              style="width: 600px"
            ></el-input>
            <el-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="
                isShowPwd = false;
                formAddAdmin1.newPassword = '';
              "
              class="mybotton"
              >返回</el-button
            >
          </div>
          <el-button
            v-else
            type="primary"
            style=""
            size="small"
            @click="isShowPwd = true"
            class="mybotton"
            >去修改</el-button
          >
        </el-form-item>
        <el-form-item label="角色" prop="newUserRoleId">
          <el-select
            v-model="formAddAdmin1.newUserRoleId"
            size="small"
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel1">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="updateUser('formAddAdmin1')"
            :loading="btnLoad"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="searchUserVisible"
      width="500px">
      <el-form ref="userFormRef" :model="userForm" label-width="110px">
        <el-form-item label="头像：">
          <div class="user-data">
            <img :src="userForm.heardImg" alt="" class="user-search-img">
          </div>
        </el-form-item>
        <el-form-item label="名字：">
          <div class="user-data">{{userForm.userName}}</div>
        </el-form-item>
        <el-form-item label="出生日期：">
          <div class="user-data">{{userForm.birthday}}</div>
        </el-form-item>
        <el-form-item label="年龄：">
          <div class="user-data">{{userForm.age}}</div>
        </el-form-item>
        <el-form-item label="籍贯：">
          <div class="user-data">{{userForm.province}}{{userForm.city}}{{userForm.area}}</div>
        </el-form-item>
        <el-form-item label="性别：">
          <div class="user-data">{{userForm.gender===1?'男':userForm.gender===2?'女':""}}</div>
        </el-form-item>
        <el-form-item label="手机号码：">
          <div class="user-data">{{userForm.phone}}</div>
        </el-form-item>
        <el-form-item label="学历：">
          <div class="user-data">{{userForm.eductionName?userForm.eductionName:'未填写'}}</div>
        </el-form-item>
        <el-form-item label="期望薪资：">
          <div class="user-data">{{userForm.expectSalaryName?userForm.expectSalaryName:'未填写'}}</div>
        </el-form-item>
        <el-form-item label="邮箱：">
          <div class="user-data">{{userForm.email?userForm.email:'未填写'}}</div>
        </el-form-item>
        <el-form-item label="简历类型：">
          <div class="user-data">{{userForm.typeName}}</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="searchUserVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="searchUserVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import companyRequest from '../../api/company';
import _api from "@/api/index";
import apiUrl from "@/api/public";
export default {
  data() {
    return {
      LogoUrl:"https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      searchform: {
        jobName: "",
        status: "",
      },
      searchUserVisible: false, //查看求职者信息弹出框
      userForm: {}, // 求职者信息
      tableData: [], //权限列表数据
      defaultArr: [], //默认展开行
      dialogVisible: false,
      dialogVisible1: false,
      formAddAdmin: {
        userName: "",
        account: "",
        password: "",
        newUserRoleId: null,
        url: "",
      },
      formAddAdmin1: {
        userName: "",
        newPassword: "",
        newUserRoleId: null,
        url: "",
      },
      rules: {
        userName: [
          { required: true, message: "用户昵称不能为空", trigger: "blur" },
        ],
        account: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        newUserRoleId: [
          { required: true, message: "权限不能为空", trigger: "blur" },
        ],
      },
      list: [],
      data: [],
      loading: false,

      labelPosition: "right", //控制表单对齐方式
      btnLoad: false,
      id: null,
      userInfo: {},
      total: 0,
      pageNum: 1,
      pageSize: 10,
      userName: "",
      account: "",
      roleId: 1,
      inputSeache: "",
      roleList: [],
      userRoleId: "",
      userStatus: null,
      isShowPwd: false,
      userType: null,
      userGender: null,
    };
  },
  created() {
    this.userInfo = window.localStorage.getItem("userInfo");
    this.getAdminUser();
    this.getRoleList();
  },
  methods: {
    changestatu(status, row) {
      //切换发布状态
      _api
        .post(apiUrl.amdinLockUser, {
          id: row.id,
          status: status,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRoleList() {
      _api
        .get(apiUrl.queryAdminRole, {})
        .then((res) => {
          if (res.success) {
            let roleList = [];
            let data = res.data;
            data.forEach((it) => {
              roleList.push({
                roleId: it.id,
                roleName: it.roleName,
              });
            });
            this.roleList = roleList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getupData(url) {
      this.ruleForm.imgUrl = url;
    },
    getimgurl(data) {
      this.formAddAdmin.url = data;
    },
    // 取消
    canvel() {
      this.dialogVisible = false;
      this.formAddAdmin.account = this.formAddAdmin.userName = this.formAddAdmin.password = this.formAddAdmin.newUserRoleId = this.formAddAdmin.url =
        "";
    },
    canvel1() {
      this.dialogVisible1 = false;
    },
    addUserBtn() {
      this.dialogVisible = true;
    },
    //添加用户
    addUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.formAddAdmin
          const data = {
            account: form.account,
            userName: form.userName,
            password: form.password,
            roleId: form.newUserRoleId,
            headImg: form.url,
          };
          _api
            .get(apiUrl.checkAccount, {
              account: data.account,
            })
            .then((res) => {
              if (res.success) {
                this.addUser1(data);
              }
            })
            .catch((err) => {
              this.$message({
                message: "该用户名已存在",
                type: "warning",
              });
            });
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    addUser1(data) {
      _api
        .post(apiUrl.addMember, data)
        .then((res) => {
          if (res.success) {
            this.canvel();
            this.getAdminUser();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUser(formName) {
      this.btnLoad = true;
      this.$refs[formName].validate((valid) => {
        const data = {
          id: this.id,
          userName: this.formAddAdmin1.userName,
          password: this.formAddAdmin1.newPassword,
          roleId: this.formAddAdmin1.newUserRoleId,
          headImg: this.formAddAdmin1.url,
        };
        if (valid) {
          _api
            .post(apiUrl.updateMember, data)
            .then((res) => {
              if (res.success) {
                this.$message.success("修改权限成功");
                this.btnLoad = false;
                this.canvel1();
                this.getAdminUser();
              }
            })
            .catch((err) => {
              this.$message.error("信息未填写完整，请检查！");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getAdminUser() {
      this.loading = true;
      var data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userName: this.inputSeache,
        status: this.userStatus,
        type: this.userType,
        gender: this.userGender,
      }
      _api
        .post(apiUrl.adminQueryUser, data)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            console.log(res);
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCreate(id) {
      this.id = id;
      this.dialogVisible1 = true;
      this.selectMemberById(id);
    },
    selectMemberById(id) {
      _api
        .get(apiUrl.selectMemberById, {
          memberId: id,
        })
        .then((res) => {
          if (res.success) {
            this.formAddAdmin1.userName = res.data.userName;
            this.formAddAdmin1.account = res.data.account;
            this.formAddAdmin1.password = res.data.password;
            this.formAddAdmin1.newUserRoleId = res.data.roleId;
            this.formAddAdmin1.url = res.data.headImg;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchUser(id) {
      // alert(id)
      companyRequest.selectUserById({userId: id}).then((res) => {
        if (res.code === 200) {
          this.userForm = res.data
          this.searchUserVisible = true
        }
      }).catch((err) => {
        this.$message.error(err)
      })
      
    },
    deleteUser(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api
            .get(apiUrl.adminDeleteUser, {
              userId: id,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("删除成功");
                this.getAdminUser();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeUserType(type, id) {
      _api
        .post(apiUrl.changeUserType, {
          userId: id,
          type: type,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handchange(data) {
      //分页Size变化
      this.pageSize = data;
      this.pageNum = 1;
      this.getAdminUser();
    },
    currentchange(data) {
      //当前页变化
      this.pageNum = data;
      this.getAdminUser();
    },
  },
};
</script>
<style>
.el-loading-spinner .path {
  stroke: #fe8196;
}
.el-switch__label {
  color: #bdbdbd;
}
.el-switch__label.el-switch__label--right.is-active {
  color: #2ad1b8;
}
.el-switch__label.el-switch__label--left.is-active {
  color: #fe8496;
}
</style>
<style scoped>
@import "../../assets/css/booth.css";
.serarch {
  overflow: hidden;
}
.user-data{
  color: #bbb;
}
.el-form-item{
  margin-bottom: 10px !important;
}
.el-dialog__body{
  padding: 20px 20px !important;
}
.user-search-img{
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  border-radius: 50%;

}
</style>
